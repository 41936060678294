<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="news-content" v-loading="loading">
            <div class="title">{{newsInfo.title}}</div>
            <div class="info">
                <span class="date">时间：{{newsInfo.publishTime ? newsInfo.publishTime.substring(0,10) : ''}}</span>
                <span class="source">来源：{{newsInfo.articleSource}}</span>
                <span class="count">浏览量：{{newsInfo.views}}</span>
            </div>
            
            <div class="desc" v-html="newsInfo.content" id="desc"></div>
            <div>

                <div class="down-item" v-for="(item, index) in relateAttach" :key="index">
                    <a :href="'/core/base/c/download/file?attachId='+item.id"  class="down-link">附件：{{item.name}}</a>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Bread from '../components/common/Bread'
import { useRoute} from 'vue-router'
import { ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const name = ref(query.name);
        const path = ref(query.path);
        const relateAttach = ref([]);
        // console.log(query)

        const breads = ref([{text:'首页', path: '/index', name: 'Index'}]);
        if(type.value =='training'){
            breads.value = [{text: '科普与培训', path: '/training', name: 'Training'}];
        }else if(type.value == 'industry'){
            breads.value = [{text: '产业专区', path: '/industry', name: 'Industry'}];
        }else if(type.value == 'contest'){
            breads.value = [{text: '竞赛专区', path: '/contest', name: 'Contest'}];
        }else if(type.value == 'index'){
            //什么都没有
        }else if(type.value == 'organ'){
            breads.value.push({text: name.value, path: path.value, name: "Organ"});
        }
    
        function getArticleInfo(id){    //获取文章信息
            loading.value = true;
            const url = '/core/cms/a/articleContent/info?id=' + id;
            proxy.$http.post(url)
            .then(res=>{
                loading.value = false;
                if(res.data.state == 0){
                    // console.log(newsInfo)
                    newsInfo.value = res.data.result;
                    let typeName = newsInfo.value.typeName;
                    let typeId = newsInfo.value.typeId;
                    if(type.value == 'organ'){ 
                        breads.value.push({text: '文章详情（典型案例）'})
                    }else{
                        if(typeName && typeName != ''){
                            if(type.value){
                                breads.value.push({text: typeName, name:'NewsListPage', path:'/newsListPage?id='+ typeId + '&name='+ typeName + '&type='+type.value});
                            }else{
                                breads.value.push({text: typeName, name:'Institution', path:'/institution?id='+ typeId + '&name='+ typeName + '&type='+type.value});
                            }
                        }
                        breads.value.push({text: '文章详情'})
                    }
                    if(newsInfo.value.attachIdArr){
                        let idArr = newsInfo.value.attachIdArr.split(",");
                        let nameArr = newsInfo.value.attachNameArr.split(",");
                        for(let i = 0; i < idArr.length; i++){
                            relateAttach.value.push({id:idArr[i], name:nameArr[i]});
                        }
                    }

                    increase(); //增加浏览量
                    getAccessCount(); //获取浏览量
                }
            }).catch(err=>{
                loading.value = false;
            })
        }

        //获取浏览量
        function getAccessCount(){
            const url = '/core/cms/a/articleContent/getArticleAccessCount?id=' + query.id;
            proxy.$http.post(url)
            .then(res=>{
                if(res.data.state == 0){
                    newsInfo.value.views = res.data.result;
                }
            }).catch(err=>{
            })
        }

        //增加浏览量
        function increase(){
            const url = '/core/cms/a/articleContent/articleAccess?id=' + query.id;
            proxy.$http.post(url).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{
            getArticleInfo(query.id);
        })

        return {
            newsInfo, breads, loading,relateAttach
        }
    },
    components: {Bread},
    mounted(){
        
    }
 
}
</script>

<style lang="less" scoped>
.down-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #007EEC;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
    max-width: 440px;
    overflow: hidden;
    text-decoration: none;
}
.news-content{
    margin-top: 12px;
    margin-bottom: 60px;
    width: 1200px;
    overflow: auto;
    img{
        max-width: 1200px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    .desc{
        margin-top: 25px;
    }
}
</style>

