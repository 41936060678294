<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="news-content" v-loading="loading">
<!--            <div class="title">{{newsInfo.adTitle}}</div>-->
<!--            <div class="info">-->
<!--                <span class="date">时间：{{newsInfo.adDate}}</span>-->
<!--                <span class="source">来源：{{newsInfo.source}}</span>-->
<!--                <span class="count">浏览量：{{newsInfo.views}}</span>-->
<!--            </div>-->
            
            <div class="desc" id="desc">
                <p><strong><span style="font-family: 仿宋_GB2312;font-size: 21px">政策支持</span></strong></p><p style="margin-top: 0;margin-right: 0;margin-bottom: 12px;text-indent: 43px;padding: 0;line-height: 30px;background: rgb(255, 255, 255)"><span style=";font-family:仿宋_GB2312;font-size:21px">白云区出台</span><span style="font-family: 仿宋_GB2312;font-size: 21px"><span style="font-family:仿宋_GB2312">“</span><span style="font-family:Times New Roman">1+4+N</span><span style="font-family:仿宋_GB2312">”政策体系、“</span></span><span style=";font-family:仿宋_GB2312;font-size:21px">民科园创新十条</span><span style="font-family: 仿宋_GB2312;font-size: 21px">”“政企银保投试点政策”，设立工商银行科技特色支行、</span><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">广州信易贷</span><span style="font-family:仿宋_GB2312">“民科园专区”</span></span><span style="font-family: 仿宋_GB2312;font-size: 21px">，支持民营企业创新发展。</span></p><p><a href="http://www.by.gov.cn/zsyz/yhzc/bdzc/" target="_blank"><span style="text-decoration:underline;"><span style="font-family: 宋体;color: rgb(128, 0, 128);font-size: 16px"><span style="font-family:宋体">本地政策</span> - 广州市白云区人民政府门户网站 (by.gov.cn)</span></span></a></p><p><br/></p>
            </div>
        </div>
    </div>
</template>

<script>
import Bread from '../../../components/common/Bread.vue'
import { useRoute} from 'vue-router'
import { ref, onMounted, getCurrentInstance} from 'vue'
export default {
    data(){
        return {
            breads:[
                {
                    text: '特色专区',
                    name: 'SpecialIndustry',
                    path: '/specialIndustry'
                },
                {
                    text: '产业专区',
                    name: 'Industry',
                    path: '/industry'
                },{
                    text: '政策支持',
                }
            ],
            active: 0
        }
    },
    setup(){
        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const name = ref(query.name);
        const path = ref(query.path);

        function increase(){
            const url = '/hfapplication/gdsti/a/adInfo/addViews';
            proxy.$http.post(url, {id: query.id}).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{

        })

        return {
            newsInfo, loading
        }
    },
    components: {Bread},
    mounted(){
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = '/static/ueditor/ueditor.parse.min.js'
    //     document.body.appendChild(script);
    //     // console.log('mounted')
    //     uParse('#desc', {
    //         rootPath: "/static/ueditor";
    //     })
    }
 
}
</script>

<style lang="less" scoped>
.news-content{
    margin-top: 12px;
    margin-bottom: 60px;
    width: 1200px;
    overflow: auto;
    display: flex;
    justify-content: center;
    img{
        max-width: 1000px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    .desc{
        margin-top: 25px;
        width: 1000px;
    }
}
</style>

